.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}
.card > .first-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}
.logo {
  font-family: "Higuen Elegant Serif";
  color: #efc75e;
  font-size: 50vw;
}
.name {
  font-family: "Higuen Elegant Serif";
  color: white;
  font-size: 10vw;
}
hr {
  height: 2px;
  width: 80%;
  color: #efc75e;
}

svg[iconname="email"],
svg[iconname="telephone"],
svg[iconname="website"] {
  fill: white;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}
.my-info {
  color: white;
  text-align: left;
  margin-top: 1rem;
}
.my-info > p > a {
  text-decoration: none;
  color: white;
}
.qr-code {
  margin-top: 2rem;
  width: 50%;
}
