body {
  margin: 0;
  background-color: #1d1d1d !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 3rem;
}
.container-fluid {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

@font-face {
  font-family: "Higuen Elegant Serif";
  src: url("./assets/fonts/Higuen\ Serif.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Montserrat", "Times New Roman", Times, serif;
}

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #323131;
  border-radius: 0.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
