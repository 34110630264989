.panel {
  background-color: #323131;
  padding: 1rem;
  cursor: pointer;
  border-radius: 1rem;
  height: 15rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.panel > .airplane-img {
  width: 20%;
  position: absolute;
  bottom: 0;
  left: 0;
  visibility: hidden;
  display: inline;
}
.panel.showDetails > .airplane-img {
  visibility: visible;
  z-index: 0;
  animation: takeOff 1s linear forwards;
  transform-origin: bottom left;
}
@keyframes takeOff {
  0% {
    left: 0;
  }
  100% {
    transform: translateY(-4rem) rotate(-5deg);
    left: 100%;
    opacity: 0.1;
  }
}
.panel:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease;
}
.panel.showDetails {
  text-align: left;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
}
.panel > .sub-title {
  font-size: 2rem;
  color: white;
}
.panel > .sub-title > span {
  color: #efc75e;
}
.panel > .title {
  font-size: 3rem;
  color: #efc75e;
}
.bullet-point-description {
  font-size: 1.75rem;
}
@media only screen and (max-width: 960px) {
  .panel {
    height: 14rem;
  }
  .panel > .sub-title {
    font-size: 1.8rem;
  }
  .panel > .title {
    font-size: 2.5rem;
  }
  .bullet-point-description {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 850px) {
  .panel {
    height: 13rem;
  }
  .panel > .sub-title {
    font-size: 1.5rem;
  }
  .panel > .title {
    font-size: 2.25rem;
  }
  .bullet-point-description {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 715px) {
  .panel {
    height: 12rem;
  }
  .panel > .sub-title {
    font-size: 1.25rem;
  }
  .panel > .title {
    font-size: 2rem;
  }
  .bullet-point-description {
    font-size: 1.15rem;
  }
}
@media only screen and (max-width: 615px) {
  .panel {
    height: 10rem;
  }
  .panel > .sub-title {
    font-size: 1.1rem;
  }
  .panel > .title {
    font-size: 2rem;
  }
  .bullet-point-description {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 510px) {
  .panel {
    height: 9rem;
  }
  .panel > .sub-title {
    font-size: 0.65rem;
  }
  .panel > .title {
    font-size: 1rem;
  }
  .bullet-point-description {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 410px) {
  .panel {
    height: 8rem;
  }
  .panel > .sub-title {
    font-size: 0.75rem;
  }
  .panel > .title {
    font-size: 1rem;
  }
  .bullet-point-description {
    font-size: 0.6rem;
  }
  .project-info {
    font-size: 0.9rem;
  }
}
.footer-title {
  font-size: 3rem;
}
@media only screen and (max-width: 370px) {
  .footer-title {
    font-size: 2.5rem;
  }
  .project-info {
    font-size: 0.85rem;
  }
  .tag {
    font-size: 0.85rem;
  }
}
@media only screen and (max-width: 350px) {
  .project-info {
    font-size: 0.8rem;
  }
  .tag {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 320px) {
  .project-info {
    font-size: 0.75rem;
  }
  .tag {
    font-size: 0.75rem;
  }
}
@media only screen and (max-width: 300px) {
  .panel {
    height: 7rem;
  }
  .panel > .sub-title {
    font-size: 0.65rem;
  }
  .panel > .title {
    font-size: 0.75rem;
  }
  .bullet-point-description {
    font-size: 0.5rem;
  }
  .footer-title {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 280px) {
  .panel {
    height: fit-content;
  }
}
.bullet-point-description {
  color: white;
}
.projectPanel {
  background-color: #323131;
  width: 400px;
  height: 400px;
  padding: 0;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.projectPanel.hovering {
  opacity: 0.8;
  transition: opacity 0.3s ease;
  transform: scale(1.02);
  transition: transform 0.3s ease;
}
.project-image {
  width: 100%;
  height: 200px;
  border-radius: 1rem 1rem 0 0;
}
.panel-footer {
  display: flex;
  color: white;
  flex-direction: column;
  height: 100%;
  position: relative;
  margin-bottom: 0;
  justify-content: center;
  row-gap: 2rem;
  padding: 0 1rem 0 1rem;
}
.footer-actions {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
}
.footer-actions span {
  margin: 5px;
}
.tag {
  border-radius: 1rem;
  padding: 0.75rem;
  background-color: black;
  color: white;
}
.project-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}
.project-info {
  color: white;
  text-align: left;
}
.tags-container {
  display: flex;
  position: relative;
  bottom: 0;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}
