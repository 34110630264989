.main-navbar {
  background-color: #1d1d1d !important;
  padding: 0 !important;
}
.navbar-brand {
  color: #efc75e !important;
  font-size: 3rem !important;
  font-family: "Higuen Elegant Serif", "Times New Roman", Times, serif;
  padding-top: 0 !important;
  padding-bottom: 10px !important;
}
.nav-link {
  color: white !important;
  font-size: 1rem !important;
}
.nav-link.active {
  color: #efc75e !important;
}
.nav-link:hover {
  color: #dea004 !important;
}
.navbar-toggler-icon {
  background-color: white !important;
}
.navbar-toggler {
  border: none !important;
  box-shadow: none !important;
}
