.welcome-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}
.welcome-title {
  color: #efc75e;
  font-size: 12vw;
  font-family: "Higuen Elegant Serif", "Times New Roman", Times, serif;
}
.welcome-sub-title {
  color: white;
  font-size: 4vw;
  font-family: "Higuen Elegant Serif", "Times New Roman", Times, serif;
}
