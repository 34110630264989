.form-label {
  color: white;
}
.form-group {
  text-align: left;
}

.form-control,
.form-control:focus,
.form-control:active {
  background-color: #323131;
  box-shadow: none;
  border: 1px solid transparent;
  color: white;
}
.form-control:focus {
  border: 1px solid white;
}

.form-control::placeholder {
  color: rgb(125, 120, 120) !important;
}
.success_message {
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.send-icon {
  width: 1rem;
}
.success_message > h1 > .checkmark {
  fill: rgb(7, 201, 7);
  width: 2rem;
}
.submit-button,
.submit-button:focus,
.submit-button:active {
  box-shadow: none;
  background-color: #0277b5;
  border: none;
}
.submit-button:hover {
  background-color: rgb(7, 201, 7);
}
.logos {
  display: flex;
  gap: 2rem;
  justify-content: center;
  padding-bottom: 1rem;
  align-items: center;
}
.logos img {
  width: 6vw;
  height: 6vw;
}
.logos img[alt="email address"] {
  width: 8vw;
  cursor: pointer;
}
.contact-form {
  height: 22rem;
}
