.sections > div {
  padding: 0 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.section {
  margin-bottom: 1rem;
}
.projects {
  display: flex;
  gap: 5rem;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.section-title {
  display: flex;
  justify-content: left;
  color: white;
  text-decoration: underline;
  margin-top: 0.5rem;
}
